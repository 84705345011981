<template>
  <div>
    <BaseNav></BaseNav>

    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div
          class="
            content
            d-flex
            justify-content-center
            align-items-center
            text-left
          "
        >
          <!-- Registration form -->
          <form
            class="flex-fill"
            ref="registerForm"
            @submit.prevent="submitRegister"
          >
            <div class="row">
              <div class="col-lg-6 offset-lg-3">
                <div class="card mb-0 shadow rounded">
                  <div class="card-body">
                    <div class="text-center mb-3">
                      <i
                        class="
                          icon-plus3 icon-2x
                          text-success
                          border-success border-3
                          rounded-round
                          p-3
                          mb-3
                          mt-1
                        "
                      ></i>
                      <h5 class="mb-0">Create a writer account</h5>
                      <span class="d-block text-muted"
                        >All fields are required</span
                      >
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="">First name</label>
                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.first_name"
                            name="first_name"
                            type="text"
                            class="form-control"
                            placeholder="First name"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-check text-muted"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <label for="">Last name</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.last_name"
                            name="last_name"
                            type="text"
                            class="form-control"
                            placeholder="Second name"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-check text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <label for="">Email Address</label>

                      <input
                        required
                        v-model="registerForm.email"
                        name="email"
                        type="email"
                        class="form-control"
                        placeholder="Email Address"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-mention text-muted"></i>
                      </div>
                    </div>

                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <label for="">Editor Email Address</label>

                      <input
                        required
                        v-model="registerForm.editor_email"
                        name="editor_email"
                        type="email"
                        class="form-control"
                        placeholder="Editor Email Address"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-mention text-muted"></i>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <label for="">Country</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <vue-select
                            label="name"
                            v-model="registerForm.country"
                            placeholder="Country: Start typing to search"
                            :options="countryNames"
                            :reduce="(country) => country.name"
                          >
                          </vue-select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label for="">Phone</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.phone"
                            name="phone"
                            type="tel"
                            class="form-control"
                            placeholder="Your Phone"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-phone text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="">Password</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.password"
                            name="password"
                            type="password"
                            class="form-control"
                            placeholder="Create password"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-lock text-muted"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <label for="">Password Confirmation</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.password_confirmation"
                            name="password_confirmation"
                            type="password"
                            class="form-control"
                            placeholder="Repeat password"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-lock text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-input-styled"
                            checked
                            data-fouc
                          />
                          Send me <a href="#">test account settings</a>
                        </label>
                      </div>

                      <!-- <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-input-styled"
                            checked
                            data-fouc
                          />
                          Subscribe to monthly newsletter
                        </label>
                      </div>

                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-input-styled"
                            data-fouc
                          />
                          Accept <a href="#">terms of service</a>
                        </label>
                      </div> -->
                    </div>

                    <button
                      :disabled="registerForm.busy"
                      type="submit"
                      class="btn bg-primary-400 btn-labeled btn-labeled-right"
                    >
                      <b><i class="icon-plus3"></i></b>
                      {{ registerForm.busy ? "Loading.." : "Create Account" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- /registration form -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { countries } from "countries-list";
import Alerts from "../../../mixins/alerts";

export default {
  data() {
    return {
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        editor_email: "",
        phone: "",
        country: "",
        password: "",
      }),
    };
  },

  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country].name);
    },
    ...mapState("auth", ["loginForm"]),
  },

  methods: {
    submitRegister() {
      this.$req
        .apiFormPost("/Writers/Register/", this.registerForm)
        .then((res) => {
          console.log("res.data", res.data);
          this.$notify({
            title: "Success",
            text: "Registration successiful!",
          });
          // login
          this.$req
            .apiFormPost("/Auth/Login/", this.registerForm)
            .then((res) => {
              localStorage.setItem(
                "token",
                `Bearer ${res.data.Data.access_token}`
              );
              document.location.href = "/home/";
            })
            .catch((err) => {
              console.log(err);
              this.errorNoti(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.errorNoti(err);
        });
    },
  },

  mixins: [Alerts],
};
</script>
